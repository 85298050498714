import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../styles/index.scss"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <h1>Hello</h1>
    <h2>I'm trying to build a better future for humanity.</h2>
    <p>Learn how you can join in the fight.</p>
    <StaticImage
      src="../images/gatsby-astronaut.png"
      width={300}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="A Gatsby astronaut"
      style={{ marginBottom: `1.45rem` }}
    />
  </Layout>
)

export default IndexPage
